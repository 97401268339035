

h1{
    margin-top: 100px;
    font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
}

.btn{
    margin-left: 100px;
}
.div-container{
    margin-top: 50px;
    margin-left: 200px;

}

.div{
    height: 200px;
    width: 80%;
    border: 1px solid black;
    margin-left: 50px;
}
 