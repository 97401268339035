

.auth-container {
  max-width: 400px;
  margin-top: 100px;
  margin-left: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

h2 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

label {
  margin-bottom: 8px;
  color: #333;
}

input {
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

button {
  background-color: #a987a2;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
 button:hover {
  background-color: #eea19b; /* Darker Red */
}

@media screen and (max-width: 600px) {
  .auth-container {
    margin: 50px auto;
  }
}

.gender-options {
  display: flex;
  justify-content: space-around;
}

.gender-options > div {
  margin-right: 10px; /* Adjust the gap between radio buttons and labels */
}


