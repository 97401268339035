/* General Resets */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
}

/* Container and Layout */
.container {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px; /* Space between rows */
}

/* Heading */
.heading {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}

.heading h2 {
  margin-bottom: 20px;
}

/* Image Container */
.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 10px; /* Add horizontal margin between images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow effect */
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure child elements are contained within the container */
  width: 200px; /* Fixed width for all images */
}

.image-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.image-container img {
  display: block; /* Remove bottom margin/gap */
  width: 100%; /* Full width of the container */
  height: auto;
  border-radius: 8px; /* Match container's rounded corners */
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-container:hover img {
  transform: scale(1.1);
}

.auto-name {
  font-family: Arial, sans-serif;
  font-size: 20px;
  line-height: 1.6;
  color: #333;
}

.image-title {
  position: absolute;
  font-weight: bold;
  font-size: larger;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(88, 11, 119);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px; /* Space above image title */
  color: #4c6c80;
}

/* Ping */
.ping {
  margin: 20px 0; /* Vertical spacing */
  border-radius: 20px;
  background-color: #fef6f1;
  text-align: center;
}

.ping img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  width: 400px; /* Set specific width */
  height: auto;
}

/* Bespoke */
.bespoke {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 20px; /* Space above heading */
}




/* Video Container */

/* General Resets */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
}

/* Container and Layout */
.container {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px; /* Space between rows */
}

/* Heading */
.heading {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}

.heading h2 {
  margin-bottom: 20px;
}

/* Image Container */
.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 10px; /* Add horizontal margin between images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow effect */
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure child elements are contained within the container */
  width: 200px; /* Fixed width for all images */
}

.image-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.image-container img {
  display: block; /* Remove bottom margin/gap */
  width: 100%; /* Full width of the container */
  height: auto;
  border-radius: 8px; /* Match container's rounded corners */
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-container:hover img {
  transform: scale(1.1);
}

.auto-name {
  font-family: Arial, sans-serif;
  font-size: 20px;
  line-height: 1.6;
  color: #333;
}

.image-title {
  text-align: center;
  margin-top: 10px; /* Space above image title */
  font-size: 16px;
  color: #4c6c80;
}

/* Ping */
.ping {
  margin: 20px 0; /* Vertical spacing */
  border-radius: 20px;
  background-color: #fef6f1;
  text-align: center;
}

.ping img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  width: 400px; /* Set specific width */
  height: auto;
}

/* Bespoke */
.bespoke {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 20px; /* Space above heading */
}

.bespoke h2 {
  margin: 20px 0;
}

/* Video Container */
