.customer-review {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review-heading {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 30px;
}

.review {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.review img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.review-text {
  font-size: 16px;
  line-height: 1.6;
}

.review-text p {
  margin: 0;
}

.review-text strong {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

.show-more-button {
  text-align: center;
  margin-top: 10px;
}

.show-more-button button {
  background-color: #1b2a33;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 50px;
}

.show-more-button button:hover {
  background-color: #a4b5b9;
}
