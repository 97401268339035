.image-containers {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
   
  }
  
  .heading {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 50px;
  }
  .image-wrapper {
    position: relative;
    width: 550px;
    height: 200px;
    
  }
  
  .image-wrapper img {
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 0.3s ease;
    border-radius:2%;
  }
  
  .image-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .image-wrapper:hover img {
    transform: scale(1.03);
  }
  
  .image-wrapper:hover .overlay {
   
    color: #ff0;
  }
  