/* CartPage.css */

.cart-item {
  display: flex;
 position: relative;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow items to wrap to next line if needed */
}

.cart-item img {
  position: relative;
  left: 50px;
  width: 100%; /* Make image responsive to its container */
  max-width: 200px; /* Limit maximum width */
  margin-left: 20px; /* Adjust margin for smaller screens */
  margin-right: 20px;
}

.cart-item-details {
  flex: 1;
  position: relative;
  left: 50px;
}
h3{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 25px;
  font-style: normal;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
}

.btn1 {
  background-color: #a987a2; /* Red */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 30px; /* Adjust margin for smaller screens */
  margin-left: auto; /* Push button to the right */
  cursor: pointer;
  border-radius: 18px;

}

.btn1:hover {
  background-color: #dd1919; /* Darker Red */
}

/* CartItem.css */

.qty {
  position: relative;
  display: flex;
  background-color: #a987a2;
  align-items: center;
  margin-left: auto;
  bottom: 40px;
  margin-right: 100px; 
  margin-bottom: 60px; 
  color: white;
  height: 37px;
  max-width: 100px; /* Limit maximum width */
  border-radius: 18px;
  
}

.qty button {
  background-color: #a987a2;
  color: white;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 10px 14px;
  font-size: 15px;
  border-radius: 18px;
  
}




.qty span {
  margin: 0 10px;
  font-size: 18px;
}
