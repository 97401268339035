

h1{
    margin-top: 100px;
    font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
}

.btn{
    margin-left: 100px;
}
.div-container{
    margin-top: 50px;
    margin-left: 200px;

}

.div{
    height: 200px;
    width: 80%;
    border: 1px solid black;
    margin-left: 50px;
}
 

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    left: 100px;
    width: calc(25% - 20px); /* Adjust the width as needed */
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust margin as needed */
  }
  
  @media (max-width: 992px) {
    .card {
      width: calc(33.33% - 10px); /* Adjust the width as needed for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .card {
      width: calc(50% - 10px); /* Adjust the width as needed for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .card {
      width: calc(100% - 10px); /* Adjust the width as needed for smaller screens */
    }
  }
 

  .card-title{
    text-align: center;
    margin-left: 70px;
  }

  .card-text{
    margin-left: 30px;
  }

  .add{
    background-color: #a987a2; /* Red */
  color: white;
  border: none;
  padding: 10px 20px; /* Adjust padding to increase button size */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px; /* Increase font size */
  margin-left: auto; /* Push button to the right */
  cursor: pointer;
  margin-left: 70px;
  border-radius: 18px;
  
  }
  .add:hover {
    background-color: #eea19b; /* Darker Red */
  }

  .card-img-top {
    transition: transform 0.3s ease;
  }
  
  .card-img-top:hover {
    transform: scale(1.3); /* Adjust the scale factor as needed */
  }

 

  
  
  
  .product-info {
    flex: 1;
  }
  
  .actions {
    display: flex;
    justify-content: center; /* Center-align the items horizontally */
    align-items: center;
  }
  
 
  
  .add {
    margin-left: 60px; /* Adjust the margin to your preference */
  }
  