.ship {
  margin-top: 100px;
  padding: 0 1em; /* Add padding for better spacing on small screens */
}

.container1 {
  max-width: 30em; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Centers the container horizontally */
  text-align: left; /* Aligns content from left to right */
  background-color: #fff; /* Ensure background color for readability */
  padding: 1em; /* Add padding for better spacing */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  border-radius: 8px; /* Optional: Rounded corners */
}

.container1 p {
  line-height: 1.6; /* Adjust line height as needed */
  word-wrap: break-word; /* Ensure words break properly */
  margin-bottom: 1em; /* Add space between paragraphs */
}

.container1 ul {
  padding-left: 20px; /* Add left padding for list items */
  margin-bottom: 1em; /* Add space below the list */
}

.container1 li {
  margin-bottom: 0.5em; /* Adjust spacing between list items as needed */
}

.container1 a {
  color: #007bff; /* Blue color for email link */
  text-decoration: none; /* Remove underline */
}

.container1 a:hover {
  text-decoration: underline; /* Underline on hover */
}

@media (max-width: 768px) {
  .container1 {
    padding: 1em; /* Reduce padding for smaller screens */
  }

  .container1 h2 {
    font-size: 1.5em; /* Adjust font size for headers on smaller screens */
  }
}
