.container2 {
    margin-top: 100px;
}

h2 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 30px;
    font-style: normal;
}

.wishlist-container {
    display: flex;
    flex-direction: column;
   
  }
  
  .wishlist-item {
    position: relative;
    left: 0; /* Adjusted left position to 0 */
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.wishlist-item img {
    max-width: 200px; /* Adjust the image size as needed */
    height: auto;
    position: relative;
    left: 100px;
    margin-right: 20px; /* Adjust the spacing between image and info as needed */
}

.wishlist-info {
  position: relative;
  left: 100px;
    flex: 1; /* Take remaining space */
}
.wishlist-info h4{
  font-size: 25px;
}
.wishlist-info p{
  font-size: 20px;
}

.wishlist-actions {
    position: relative;
    right: 150px;
    display: flex;
    align-items: center;
}

.wishlist-item:not(:last-child) {
    /* Adjust color and style as needed */
    padding-bottom: 20px; /* Adjust spacing between product and line as needed */
}
.empty-wishlist-message {
  text-align: center;
}
